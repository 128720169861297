'use strict'

import axios from 'axios'
import algoliasearch from 'algoliasearch/lite'

import store from '../store'

const ALGOLIA_APP_ID = 'IDRL65QUBR'
const ALGOLIA_APP_KEY = '415255c1e3b1db0381a8829d535573fd'

const ALGOLIA_ANALYTICS_BASEURL = 'https://analytics.algolia.com'
const ALGOLIA_ANALYTICS_APP_KEY = 'cedb12f9da86c413c0d2e3a4e10498a6'

// no dev replicas in algolia
const ALGOLIA_INDEX_PREFIX = process.env.NODE_ENV === 'production' ? 'prod' : 'prod'

const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_APP_KEY)
const ingredientsIndex = client.initIndex('ingredients')
const recipesIndex = client.initIndex(`${ALGOLIA_INDEX_PREFIX}_recipes_recipes`)
const productsPublic = client.initIndex(`${ALGOLIA_INDEX_PREFIX}_labellevie`)
const productsIndex = client.initIndex(`${ALGOLIA_INDEX_PREFIX}_products`)
const productsOrderByCreatedAtIndex = client.initIndex(`${ALGOLIA_INDEX_PREFIX}_products_by_created_at`)
const productsOrderByUpdateAtIndex = client.initIndex(`${ALGOLIA_INDEX_PREFIX}_products_by_updated_at`)

const _algoliaProductsParams = {
  hitsPerPage: 25,
  page: 0,
  facets: '*',
  facetFilters: [],
  numericFilters: [],
  restrictSearchableAttributes: ['selling_name', 'primeur_origin']
}

const _indexesMap = {
  'created_at': productsOrderByCreatedAtIndex,
  '-created_at': productsOrderByCreatedAtIndex,
  'updated_at': productsOrderByUpdateAtIndex,
  '-updated_at': productsOrderByUpdateAtIndex
}

const _zonesRegex = /^zone_(.+)$/
const _areaIntervalRegex = /^(\d+)-(\d+)$/
const _areaShelfIntervalRegex = /^(\d+).(\d+)-(\d+).(\d+)$/
const _areaSecIntervalRegex = /^(\d+)-(\d+)\/(\d+)-(\d+)$/
const _areaMultiRegex = /^(\d+)\/(\d+)$/

export default {
  centers: [],
  sites: [],
  setCenters () {
    if (this.centers.length) return
    this.centers = store.getCenters().filter(o => o.status > 0).map(o => o.id)
  },
  setSites () {
    if (this.sites.length) return
    this.sites = store.getSites().filter(o => o.status > 0).map(o => o.id)
  },
  getClient () {
    return client
  },
  getDefaultIndexName () {
    return `${ALGOLIA_INDEX_PREFIX}_products`
  },
  getRecipesIndexName () {
    return `${ALGOLIA_INDEX_PREFIX}_recipes_recipes`
  },

  addCentersFilters (params, centers, isExclusive) {
    const _prefixes = centers.map(id => `by_centers.${id}`)
    let _numericFilters = []

    let _stockfilter = ''
    if (typeof params.stock !== 'undefined') {
      if (params.stock === -1) _stockfilter = 'stock_quantity<0'
      else if (params.stock === 0) _stockfilter = 'stock_quantity<=0'
      else _stockfilter = 'stock_quantity>0'
    }

    let _zonefilters = []
    if (typeof params.zone !== 'undefined') {
      let _match = _zonesRegex.exec(params.zone) // zone_ (from center-group-area api)
      if (_match) {
        const _groupAreas = store.getGroupAreas(9, _match[1])
        if (_groupAreas) {
          _zonefilters.push(_groupAreas.filters.map(zone => `area:${zone[0]} TO ${zone[1]}`))
        }
      }

      if (!_match) {
        _match = _areaIntervalRegex.exec(params.zone) // 100-200
        if (_match) {
          _zonefilters.push(`area:${_match[1]} TO ${_match[2]}`)
        }
      }
      if (!_match) {
        _match = _areaShelfIntervalRegex.exec(params.zone) // 100.10-100.20
        if (_match) {
          _zonefilters.push(`area:${_match[1]} TO ${_match[3]}`)
          _zonefilters.push(`shelf:${_match[2]} TO ${_match[4]}`)
        }
      }
      if (!_match) {
        _match = _areaSecIntervalRegex.exec(params.zone) // // 100.10/100.20
        if (_match) {
          _zonefilters.push(`area>=${_match[1]}`)
          _zonefilters.push([`shelf<=${_match[2]}`, `area>=${_match[3]}`])
          _zonefilters.push(`shelf<=${_match[4]}`)
        }
      }
      if (!_match) {
        _match = _areaMultiRegex.exec(params.zone) // 100/200
        if (_match) {
          _zonefilters.push([`area=${_match[1]}`, `area=${_match[2]}`])
        }
      }
      if (!_match) {
        if (params.zone.indexOf('>') === 0) {
          _zonefilters.push(`area${params.zone}`)
        } else {
          _zonefilters.push(`area=${params.zone}`)
        }
      }
    }

    if (isExclusive) { // AND
      _prefixes.forEach(prefix => {
        if (typeof params.is_order_asif_no_order !== 'undefined') {
          _numericFilters.push([`${prefix}.is_order_asif_no_order=${params.is_order_asif_no_order ? '1' : '0'}`])
        }
        if (typeof params.is_order_asif_stock_empty !== 'undefined') {
          _numericFilters.push([`${prefix}.is_order_asif_stock_empty=${params.is_order_asif_stock_empty ? '1' : '0'}`])
        }
        if (typeof params.is_cross_docking_enabled !== 'undefined') {
          _numericFilters.push([`${prefix}.is_cross_docking_enabled=${params.is_cross_docking_enabled ? '1' : '0'}`])
        }
        if (typeof params.unlimited_stocks !== 'undefined') {
          _numericFilters.push([`${prefix}.unlimited_stocks=1`])
        }

        if (typeof params.zone !== 'undefined') {
          _zonefilters.forEach(zonefilter => {
            _numericFilters.push(Array.isArray(zonefilter) ? zonefilter.map(zonefilteritem => `${prefix}.${zonefilteritem}`) : [`${prefix}.${zonefilter}`])
          })
        }
        if (typeof params.quantity_ideal !== 'undefined') {
          _numericFilters.push([`${prefix}.quantity_ideal${params.quantity_ideal ? '>0' : '<=0'}`])
        }
        if (typeof params.stock !== 'undefined') {
          _numericFilters.push([`${prefix}.${_stockfilter}`])
        }
      })
    } else { // OR - if indifferent filter
      if (typeof params.is_order_asif_no_order !== 'undefined') {
        _numericFilters.push(_prefixes.map(prefix => `${prefix}.is_order_asif_no_order=${params.is_order_asif_no_order ? '1' : '0'}`))
      }
      if (typeof params.is_order_asif_stock_empty !== 'undefined') {
        _numericFilters.push(_prefixes.map(prefix => `${prefix}.is_order_asif_stock_empty=${params.is_order_asif_stock_empty ? '1' : '0'}`))
      }
      if (typeof params.is_cross_docking_enabled !== 'undefined') {
        _numericFilters.push(_prefixes.map(prefix => `${prefix}.is_cross_docking_enabled=${params.is_cross_docking_enabled ? '1' : '0'}`))
      }
      if (typeof params.unlimited_stocks !== 'undefined') {
        _numericFilters.push(_prefixes.map(prefix => `${prefix}.unlimited_stocks=1`))
      }

      if (typeof params.zone !== 'undefined') {
        _zonefilters.flat().forEach(zonefilter => {
          _numericFilters.push(_prefixes.map(prefix => `${prefix}.${zonefilter}`))
        })
      }
      if (typeof params.quantity_ideal !== 'undefined') {
        _numericFilters.push(_prefixes.map(prefix => `${prefix}.quantity_ideal${params.quantity_ideal ? '>0' : '<=0'}`))
      }
      if (typeof params.stock !== 'undefined') {
        _numericFilters.push(_prefixes.map(prefix => `${prefix}.${_stockfilter}`))
      }
    }

    if (_numericFilters.length) {
      _algoliaProductsParams.numericFilters = _algoliaProductsParams.numericFilters.concat(_numericFilters)
    }
  },
  addSitesFilters (params, sites, isExclusive) {
    const _prefixes = sites.map(id => `by_sites.${id}`)
    let _facetFilters = []
    let _numericFilters = []

    if (isExclusive) { // AND
      _prefixes.forEach(prefix => {
        if (typeof params.categorization !== 'undefined') {
          ['0', '1'].includes(params.categorization)
            ? _numericFilters.push([`${prefix}.no_categories=${params.categorization}`])
            : _numericFilters.push([`${prefix}.has_active_categories=1`])
        }
        if (typeof params.gross_margin_negative !== 'undefined') {
          _numericFilters.push([`${prefix}.gross_margin${params.gross_margin_negative ? '<0' : '>=0'}`])
        }
        if (typeof params.category_id !== 'undefined') {
          _facetFilters.push([`${prefix}.categories:${params.category_id}`])
        }
        if (typeof params.gross_selling_price_between !== 'undefined') {
          _numericFilters = _numericFilters.concat(this.getGrossSellingPriceBetweenFilter(params, prefix))
        }
      })
    } else { // OR - if indifferent filter
      if (typeof params.categorization !== 'undefined') {
        const categorizationFilters = _prefixes.map(prefix =>
          ['0', '1'].includes(params.categorization)
            ? `${prefix}.no_categories=${params.categorization}`
            : `${prefix}.has_active_categories=1`
        )
        _numericFilters.push(categorizationFilters)
      }
      if (typeof params.gross_margin_negative !== 'undefined') {
        _numericFilters.push(_prefixes.map(prefix => `${prefix}.gross_margin${params.gross_margin_negative ? '<0' : '>=0'}`))
      }
      if (typeof params.category_id !== 'undefined') {
        _facetFilters.push(_prefixes.map(prefix => `${prefix}.categories:${params.category_id}`))
      }
      if (typeof params.gross_selling_price_between !== 'undefined') {
        _numericFilters = _numericFilters.concat(_prefixes.map(prefix => {
          return this.getGrossSellingPriceBetweenFilter(params, prefix)
        }).flat())
      }
    }

    if (_facetFilters.length) {
      _algoliaProductsParams.facetFilters = _algoliaProductsParams.facetFilters.concat(_facetFilters)
    }
    if (_numericFilters.length) {
      _algoliaProductsParams.numericFilters = _algoliaProductsParams.numericFilters.concat(_numericFilters)
    }
  },
  getSaleReasonFilter (params, prefix) {
    if (+params.sale_reason === 100) return [`${prefix}.sale_reason>=1`]
    return [`${prefix}.sale_reason=${params.sale_reason}`]
  },
  getPurchasePriceBetweenFilter (params) {
    let _priceFilter = []
    if (+params.purchase_price_between === 0) {
      _priceFilter.push([`purchase_price<=0`])
    } else if (+params.purchase_price_between === 10) {
      _priceFilter.push([`purchase_price>=0`])
      _priceFilter.push([`purchase_price<25`])
    } else if (+params.purchase_price_between === 20) {
      _priceFilter.push([`purchase_price>=25`])
      _priceFilter.push([`purchase_price<50`])
    } else if (+params.purchase_price_between === 30) {
      _priceFilter.push([`purchase_price>=50`])
      _priceFilter.push([`purchase_price<100`])
    } else if (+params.purchase_price_between === 40) {
      _priceFilter.push([`purchase_price>=50`])
    } else if (+params.purchase_price_between === 50) {
      _priceFilter.push([`purchase_price>=100`])
    }
    return _priceFilter
  },
  getGrossSellingPriceBetweenFilter (params, prefix) {
    let _priceFilter = []
    if (+params.gross_selling_price_between === 0) {
      _priceFilter.push([`${prefix}.gross_selling_price<=0`])
    } else if (+params.gross_selling_price_between === 10) {
      _priceFilter.push([`${prefix}.gross_selling_price>=0`])
      _priceFilter.push([`${prefix}.gross_selling_price<25`])
    } else if (+params.gross_selling_price_between === 20) {
      _priceFilter.push([`${prefix}.gross_selling_price>=25`])
      _priceFilter.push([`${prefix}.gross_selling_price<50`])
    } else if (+params.gross_selling_price_between === 30) {
      _priceFilter.push([`${prefix}.gross_selling_price>=50`])
      _priceFilter.push([`${prefix}.gross_selling_price<100`])
    } else if (+params.gross_selling_price_between === 40) {
      _priceFilter.push([`${prefix}.gross_selling_price>=50`])
    } else if (+params.gross_selling_price_between === 50) {
      _priceFilter.push([`${prefix}.gross_selling_price>=100`])
    }
    return _priceFilter
  },
  addCentersAndSitesFilters (params, centers, isCentersExclusive, sites, isSitesExclusive) {
    let _prefixes = []
    let salereasonfilter
    centers.forEach(centerId => {
      sites.forEach(siteId => {
        _prefixes.push(`by_centers_and_sites.${siteId}-${centerId}`)
      })
    })

    if (isCentersExclusive && isSitesExclusive) { // AND - centers/sites
      _prefixes.forEach(prefix => {
        if (typeof params.sale_reason !== 'undefined') {
          _algoliaProductsParams.numericFilters.push(this.getSaleReasonFilter(params, prefix))
        }
      })
    } else if (isCentersExclusive) { // AND - centers / OR - sites
      centers.forEach(centerId => {
        if (typeof params.sale_reason !== 'undefined') {
          salereasonfilter = sites.map(
            siteId => this.getSaleReasonFilter(params, `by_centers_and_sites.${siteId}-${centerId}`)
          )
          _algoliaProductsParams.numericFilters.push(salereasonfilter.flat())
        }
      })
    } else if (isSitesExclusive) { // OR - centers / AND - sites
      sites.forEach(siteId => {
        if (typeof params.sale_reason !== 'undefined') {
          salereasonfilter = centers.map(
            centerId => this.getSaleReasonFilter(params, `by_centers_and_sites.${siteId}-${centerId}`)
          )
          _algoliaProductsParams.numericFilters.push(salereasonfilter.flat())
        }
      })
    } else { // OR - centers/sites
      if (typeof params.sale_reason !== 'undefined') {
        salereasonfilter = _prefixes.map(
          prefix => this.getSaleReasonFilter(params, prefix)
        )
        _algoliaProductsParams.numericFilters.push(salereasonfilter.flat())
      }
    }
  },
  getProducts (params) {
    this.setCenters()
    this.setSites()

    _algoliaProductsParams.userToken = store.getUser().id || 'anonymous'
    _algoliaProductsParams.hitsPerPage = params.limit || 25
    _algoliaProductsParams.page = params.page || 0
    _algoliaProductsParams.facetFilters = []
    _algoliaProductsParams.numericFilters = []

    params.centers = params.centers || []
    const _centers = params.centers.length > 0 ? params.centers : this.centers
    const _isExclusiveCenters = params.centers.length > 0
    this.addCentersFilters(params, _centers, _isExclusiveCenters)

    params.sites = params.sites || []
    const _sites = params.sites.length > 0 ? params.sites : this.sites
    const _isExclusiveSites = params.sites.length > 0
    this.addSitesFilters(params, _sites, _isExclusiveSites)

    this.addCentersAndSitesFilters(params, _centers, _isExclusiveCenters, _sites, _isExclusiveSites)

    if (typeof params.supplier !== 'undefined') {
      _algoliaProductsParams.numericFilters.push([`supplierreference_set.supplier=${params.supplier}`])
    } else if (typeof params.suppliers !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(params.suppliers.map(id => `supplierreference_set.supplier=${id}`))
    }
    if (typeof params.is_unpack !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['supplierreference_set.is_unpack=' + (params.is_unpack ? '1' : '0')])
    }
    if (typeof params.supplier_buying_type !== 'undefined') {
      _algoliaProductsParams.facetFilters.push([`supplierreference_set.buying_type:${params.supplier_buying_type}`])
    }
    if (typeof params.supplier_category !== 'undefined') {
      // _algoliaProductsParams.facetFilters.push([`supplierreference_set.category:${params.supplier_category}`])
    }
    if (typeof params.purchase_price_between !== 'undefined') {
      _algoliaProductsParams.numericFilters = _algoliaProductsParams.numericFilters.concat(this.getPurchasePriceBetweenFilter(params))
    }
    if (typeof params.disponibility !== 'undefined') {
      _algoliaProductsParams.facetFilters.push([`disponibility:${params.disponibility}`])
    }
    if (typeof params.selling_method !== 'undefined') {
      _algoliaProductsParams.facetFilters.push([`selling_method:${params.selling_method}`])
    }
    if (typeof params.no_france_delivery !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['no_france_delivery=' + (params.no_france_delivery ? '1' : '0')])
    }
    if (typeof params.do_not_categorize !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['do_not_categorize=' + (params.do_not_categorize ? '1' : '0')])
    }
    if (typeof params.imported_from !== 'undefined') {
      let _operation = null
      if (params.imported_from === -2) _operation = '=0'
      if (params.imported_from === -1) _operation = '>0'
      if (params.imported_from > 0) _operation = `=${params.imported_from}`

      if (_operation) {
        _algoliaProductsParams.numericFilters.push([`initially_created_from_external_organization_id${_operation}`])
      }
    }
    if (typeof params.dimensions !== 'undefined') {
      if (params.dimensions) {
        _algoliaProductsParams.numericFilters.push(['width>0'])
        _algoliaProductsParams.numericFilters.push(['length>0'])
        _algoliaProductsParams.numericFilters.push(['height>0'])
      } else {
        _algoliaProductsParams.numericFilters.push(['width<=0', 'length<=0', 'height<=0'])
      }
    }
    if (typeof params.weight_with_packing !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['weight_with_packing' + (params.weight_with_packing ? '>0' : '<=0')])
    }
    if (typeof params.no_allergens !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['no_allergens=' + (params.no_allergens ? '1' : '0')])
    }
    if (typeof params.no_ingredients !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['no_ingredients=' + (params.no_ingredients ? '0' : '1')])
    }
    if (typeof params.no_nutrition_facts !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['no_nutrition_facts=' + (params.no_nutrition_facts ? '1' : '0')])
    }
    if (typeof params.no_picture !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['no_picture=' + (params.no_picture ? '1' : '0')])
    }
    if (typeof params.is_super_product !== 'undefined') {
      if ([0, 1].indexOf(params.is_super_product) !== -1) {
        _algoliaProductsParams.numericFilters.push(['is_super_product=' + params.is_super_product])
      } else if (params.is_super_product === 2) {
        _algoliaProductsParams.numericFilters.push(['is_super_product=1'])
        _algoliaProductsParams.numericFilters.push(['sub_products_count=1'])
      } else if (params.is_super_product === 3) {
        _algoliaProductsParams.numericFilters.push(['is_super_product=1'])
        _algoliaProductsParams.numericFilters.push(['sub_products_count>1'])
      } else if (params.is_super_product === 4) {
        _algoliaProductsParams.numericFilters.push(['super_products_count>0'])
      }
    }
    if (typeof params.dlc_management_enabled !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['dlc_management_enabled=' + (params.dlc_management_enabled ? '1' : '0')])
    }
    if (typeof params.alcohol !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['alcohol=' + (params.alcohol ? '1' : '0')])
    }
    if (typeof params.bio !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['bio=' + (params.bio ? '1' : '0')])
    }
    if (typeof params.is_frozen !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['is_frozen=' + (params.is_frozen ? '1' : '0')])
    }
    if (typeof params.is_deposit_enabled !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['is_deposit_enabled=' + (params.is_deposit_enabled ? '1' : '0')])
    }
    if (typeof params.is_disabled_because_unsold !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['is_disabled_because_unsold=' + (params.is_disabled_because_unsold ? '1' : '0')])
    }
    if (typeof params.homemade !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['homemade=1'])
    }
    if (typeof params.prep_type !== 'undefined' && [
      'jus',
      'fruits_secs',
      'cuisine_chaude',
      'petite_fraiche_decoupe',
      'montage_salade',
      'trancheuse',
      'mise_en_pot',
      'attention_particuliere',
      'is_starter',
      'is_main_course',
      'is_dessert'
    ].indexOf(params.prep_type) !== -1) {
      _algoliaProductsParams.numericFilters.push([`${params.prep_type}=1`])
    }
    if (typeof params.epd_type !== 'undefined') {
      if (+params.epd_type === 5) {
        _algoliaProductsParams.numericFilters.push([`is_dessert=1`])
      } else if (+params.epd_type === 4) {
        _algoliaProductsParams.numericFilters.push([`is_main_course=1`])
      } else if (+params.epd_type === 3) {
        _algoliaProductsParams.numericFilters.push([`is_starter=1`])
      } else if (+params.epd_type === 2) {
        _algoliaProductsParams.numericFilters.push([`is_starter=0`])
        _algoliaProductsParams.numericFilters.push([`is_main_course=0`])
        _algoliaProductsParams.numericFilters.push([`is_dessert=0`])
      } else if (+params.epd_type === 1) {
        _algoliaProductsParams.numericFilters.push([`is_starter=1`, `is_main_course=1`, `is_dessert=1`])
      }
    }
    if (typeof params.attention_particuliere !== 'undefined') {
      _algoliaProductsParams.numericFilters.push([`attention_particuliere=1`])
    }
    if (typeof params.product_id !== 'undefined') {
      _algoliaProductsParams.facetFilters.push([`objectID:${params.product_id}`, `supplierreference_set.supplier_reference:${params.product_id}`])
    }
    if (typeof params.product_ids !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(params.product_ids.map(id => `id=${id}`))
    }
    if (typeof params.barcode !== 'undefined') {
      _algoliaProductsParams.facetFilters.push([`barcode:${params.barcode}`, `barcode_package:${params.barcode}`, `supplierreference_set.supplier_reference:${params.barcode}`])
    }
    if (typeof params.stock_quantity_group_id !== 'undefined') {
      _algoliaProductsParams.numericFilters.push([`stock_quantity_group_id=${params.stock_quantity_group_id}`])
    }
    if (typeof params.brand !== 'undefined') {
      _algoliaProductsParams.numericFilters.push([`brand_id=${params.brand}`])
    }
    if (typeof params.industrial !== 'undefined') {
      let _industrial = store.getProductIndustrial(+params.industrial)
      if (_industrial) {
        _algoliaProductsParams.facetFilters.push([`industrial:${_industrial.name}`])
      }
    }
    if (typeof params.product_typology !== 'undefined') {
      let _typologyId = +params.product_typology
      if (_typologyId === 0) {
        _algoliaProductsParams.numericFilters.push(`typology=0`)
      } else if (_typologyId === 999999) {
        _algoliaProductsParams.numericFilters.push(`typology>0`)
      } else {
        let _typologies = [_typologyId]
        let _typology = store.getProductTypology(params.product_typology)
        _typologies = _typologies.concat(_typology.descendent || [])
        _algoliaProductsParams.numericFilters.push(_typologies.map(id => `typology=${id}`))
      }
    }
    if (typeof params.retention_periods !== 'undefined') {
      _algoliaProductsParams.numericFilters.push([params.retention_periods ? 'retention_periods>0' : 'retention_periods<=0'])
    }
    if (typeof params.max_quantity_by_order !== 'undefined' && typeof params.max_quantity_by_order) {
      _algoliaProductsParams.numericFilters.push(['max_quantity_by_order>0'])
    }
    if (typeof params.lifetime_days !== 'undefined' && params.lifetime_days) {
      _algoliaProductsParams.numericFilters.push([`lifetime_days=${params.lifetime_days}`])
    }
    if (typeof params.tranche !== 'undefined' && params.tranche) {
      _algoliaProductsParams.numericFilters.push([`tranche=${params.tranche}`])
    }
    if (typeof params.ingredient !== 'undefined') {
      _algoliaProductsParams.numericFilters.push([`ingredient=${params.ingredient}`])
    }
    if (typeof params.do_not_order !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['do_not_order=' + (params.do_not_order ? '1' : '0')])
    }
    if (typeof params.is_free_zoning_enabled !== 'undefined' && params.is_free_zoning_enabled !== null) {
      _algoliaProductsParams.numericFilters.push(['is_free_zoning_enabled=' + params.is_free_zoning_enabled])
    }
    if (typeof params.is_subscription !== 'undefined') {
      _algoliaProductsParams.numericFilters.push(['is_subscription=' + (params.is_subscription ? '1' : '0')])
    }
    if (typeof params.subscription_periodicity !== 'undefined') {
      _algoliaProductsParams.numericFilters.push([`subscription_periodicity=${params.subscription_periodicity}`])
    }
    if (typeof params.tranche_gt10 !== 'undefined' && +params.tranche_gt10) {
      _algoliaProductsParams.numericFilters.push(['tranche>=10', 'weight_with_packing>=10'])
    }
    if (typeof params.tranche_gt50 !== 'undefined' && +params.tranche_gt50) {
      _algoliaProductsParams.numericFilters.push(['tranche>=50', 'weight_with_packing>=50'])
    }
    if (typeof params.tranche_gt100 !== 'undefined' && +params.tranche_gt100) {
      _algoliaProductsParams.numericFilters.push(['tranche>=100', 'weight_with_packing>=100'])
    }

    // force clear cache
    client.clearCache()

    let _currentIndex = _indexesMap[params.order_by] || productsIndex
    return _currentIndex
      .search(params.query || '', _algoliaProductsParams)
      .then(({ hits, nbHits, page, nbPages }) => {
        if (_centers.length === 1) {
          hits.forEach(o => {
            Object.assign(o, o.by_centers[_centers[0]])
          })
        }
        return { hits, page, nbHits, nbPages }
      })
  },
  getAnalyticsSearch (type) {
    let _url = `${ALGOLIA_ANALYTICS_BASEURL}/2/searches`
    if (type === 'no-results') _url += '/noResults'
    return axios
      .get(_url + '?index=prod_labellevie&limit=1000', { headers: { 'X-Algolia-API-Key': ALGOLIA_ANALYTICS_APP_KEY, 'X-Algolia-Application-Id': ALGOLIA_APP_ID } })
  },
  getPublicProducts (params) {
    this.setCenters()
    this.setSites()

    const _algoliaParams = {
      userToken: store.getUser().id || 'anonymous',
      hitsPerPage: 1000,
      page: 0,
      facets: '*',
      facetFilters: []
    }
    if (params.supplier_id) _algoliaParams.facetFilters.push([`suppliers:${params.supplier_id}`])
    if (params.category_id) {
      let _prefixes = []
      this.centers.forEach(centerId => {
        this.sites.forEach(siteId => {
          if (siteId !== 1) return
          _prefixes.push(`alternates.${siteId}-${centerId}`)
        })
      })
      _algoliaParams.facetFilters.push(_prefixes.map(prefix => `${prefix}.categories:${params.category_id}`))
    }
    return productsPublic
      .search(params.query || '', _algoliaParams)
      .then(({ hits, nbHits, page, nbPages }) => {
        return { hits, page, nbHits, nbPages }
      })
  },
  getRecipes (params) {
    const _algoliaParams = {
      userToken: store.getUser().id || 'anonymous',
      hitsPerPage: params.limit || 100,
      page: params.page || 0,
      facets: '*',
      facetFilters: [],
      numericFilters: []
    }
    if (params.recipe_ids) _algoliaParams.numericFilters.push(params.recipe_ids.map(id => `id=${id}`))
    return recipesIndex
      .search(params.query || '', _algoliaParams)
      .then(({ hits, nbHits, page, nbPages }) => {
        hits.forEach(o => {
          o.id = o.id || +o.objectID
        })
        return { hits, page, nbHits, nbPages }
      })
  },
  getIngredients (params) {
    const _algoliaParams = {
      userToken: store.getUser().id || 'anonymous',
      hitsPerPage: params.limit || 100,
      page: params.page || 0,
      facets: '*',
      facetFilters: [],
      numericFilters: []
    }
    if (params.primeur) _algoliaParams.facetFilters.push([`primeur:${params.primeur ? 'true' : 'false'}`])
    if (params.type) _algoliaParams.numericFilters.push([`type_id=${params.type}`])
    if (params.base_unit_id) _algoliaParams.numericFilters.push([`base_unit_id=${params.base_unit_id}`])
    return ingredientsIndex
      .search(params.query || '', _algoliaParams)
      .then(({ hits, nbHits, page, nbPages }) => {
        hits.forEach(o => {
          o.id = o.id || +o.objectID
        })
        return { hits, page, nbHits, nbPages }
      })
  }
}
